export enum Chambers {
  Washburn = 'chamber-washburn-1',
  Newton = 'chamber-newton-1',
  MurayCounty = 'chamber-murray-county-1',
  SaukPrairie = 'chamber-sauk-prairie-1',
  Habersham = 'chamber-habersham-1',
  Killeen = 'chamber-killeen-1',
  SantaCruz = 'chamber-santa-cruz-1',
  Encinitas = 'chamber-encinitas-1',
}
